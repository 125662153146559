module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The English Bookshop","short_name":"English Bookshop","start_url":"/","background_color":"#0f75bc","theme_color":"#0f75bc","display":"minimal-ui","icon":"src/images/englishbookshop-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
